import axios from 'axios'

const endpoint = '/api/v1/applicants'

export function getApplicants (params) {
  return axios({
    method: 'get',
    url: endpoint,
    params: params
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function getApplicant (id, cancelToken) {
  const config = {
    method: 'GET',
    url: `${endpoint}/${id}`
  }

  if (cancelToken) {
    config.cancelToken = cancelToken
  }

  return axios(config)
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function updateApplicant (id, json, method = 'patch') {
  return axios({
    method: method,
    url: `${endpoint}/${id}`,
    data: json
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err)
      throw err
    })
}

export function previewTemplate (id, json) {
  return axios({
    method: 'post',
    url: `${endpoint}/${id}/preview-template`,
    data: json
  })
    .then(function (response) {
      return response.data.data
    })
    .catch(function (error) {
      console.error(error)
      return false
    })
}

export function downloadFiles (data) {
  const controller = new AbortController();
  const signal = controller.signal

  return axios({
    method: 'post',
    url: '/api/v1/applicants/files/download',
    data: data,
    responseType: 'arraybuffer',
    signal: signal
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'applicant_files.zip')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      URL.revokeObjectURL(url)
    })
}

export function downloadCandidateReports (data) {
  const controller = new AbortController();
  const signal = controller.signal

  return axios({
    method: 'post',
    url: '/api/v1/applicants/candidate-reports/download',
    data: data,
    responseType: 'arraybuffer',
    signal: signal
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'candidate_reports.zip')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      URL.revokeObjectURL(url)
    })
}

export function getRezview (id, signal = null) {
  const config = {
    method: 'get',
    url: `/applicants/${id}/rezview`
  }
  if (signal) {
    config.signal = signal
  }
  return axios(config)
    .then(response => response.data)
    .catch(err => {
      if (!axios.isCancel(err)) {
        console.error('Error fetching rezview.', err)
        throw err
      } else {
        console.info('Cancelled fetching rezview', err)
      }
    })
}

export function getRedactedRezview (id, signal) {
  return axios({
    method: 'get',
    url: `/applicants/${id}/rezview/redacted`,
    signal: signal
  }).then(response => response.data)
    .catch(err => {
      if (!axios.isCancel(err)) {
        console.error('Error fetching rezview.', err)
        throw err
      } else {
        console.info('Cancelled fetching rezview', err)
      }
    })
}
